/********************************************************
Basic SCSS  
/********************************************************/

body,html {
     background: $white-color;
     font-family: $font-family-default;
    font-size: $font-size-default;
    text-rendering: optimizeLegibility;
    -moz-font-feature-settings: liga,kern;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    
}

input:focus {
    outline: none;
}

/********************************************************/
/* Custom Containers    
/********************************************************/

@media (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
           max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
           max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
           max-width: 1149px;
    }
}