.card-item {
  background: #FFFFFF;
  border: 1px solid #DDE1ED;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  border-radius: 10px;
  padding: 0 20px 0 20px;
  position: relative;
  //border-top: 50px solid #FF9D57;

  width: 100%;
  height: 100%;

  .card-top-section {
    border-top: 50px solid #ffe0ca;
    margin: 0px -20px;
    border-radius: 8px 8px 0 0;
  }

  .top-pick {
    margin: -35px 0px 15px -20.5px;
    opacity: 0.9;
    width: 100px;
    @media (max-width:576px) {
      margin: -35px 0px 15px -10.5px;
    }
    span {
      border: 1px solid #43aade;
      background: #43aade;
      padding: 2px 10px;
      border-radius: 0 5px 5px 0;
      color: white;
    }
  }

  .card-top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -42px;
    img {
      width: 35px;
      padding: 5px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .c-name {
      font-size: 18px;
    }
  }

  .card-name {
    min-height: 65px;
    text-align: center;
    font-size: 20px;
  }

  .card-image {
    min-width: 200px;
    max-width: 200px;
  }

  .tab-card-data {
    padding: 0;
    position: unset;
    .card-reward {
      padding: 10px 15px !important;
      border: 1px solid #dfdfdf;
      border-radius: 7px 7px 0 0;

      &.no-top-padding {
        padding-top: 0 !important;
      }
      img {
        width: 35px;
        padding: 5px;
        border-radius: 5px;
        padding-top: 2px;
      }
      .c-name {
        //display: block;
        font-size: 18px;
        color: #222222;
        margin-left: 15px;
      }

      .is-top-padding {
        //flex-direction: column;
        padding-top: 13px;
      }
    }
    .card-points {
      height: unset;
      margin: 0;
      padding: 10px 15px 15px 15px;
      border-radius: 0 0 7px 7px;
      border: none;
      font-size: 16px;
      & > div {
        padding: 0;
      }
    }
  }

  .luci-btn {
    height: 50px;
  }

}

.notes-over-popup {
  min-width: 320px;
}

.over-popup {
  &.ant-tooltip {
    max-width: 550px;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }
  .ant-tooltip-content {
    width: 100% !important;
    .ant-tooltip-inner {
      width: 100% !important;
      .graph-info {
        display: flex;
        position: relative;
        justify-content: space-between;
        .gr-item {
          align-items: center;
          display: flex;
          width: 100%;
          justify-content: space-between;
          //align-items: center;
          margin-bottom: 15px;
          position: relative;
          max-height: 35px;
          &.title {
            border-bottom: 1px solid #979797;
            //color: #696974;
            padding-bottom: 5px;
            .sp-l {
              width: 70px;
              display: block;
            }
            .sp-r {
              width: 80px;
            }
          }
          &:first-child{
            &:before{
              content:none;
            }
          }
        }
        .graph-right {
          flex: 1;
          position: relative;
          padding: 10px 5px 0px 5px;
          .gr-spending {
            font-weight: 600;
            //width: auto;
            width: 25%;
            text-align: right;
            white-space: nowrap;
          }
          .gr-reward {
            font-weight: 600;
            color: #FF8C5A;
            width: 25%;
            text-align: right;
          }
          .gr-name {
            width: 50%;
            display: flex;
            align-items: center;
            svg path {
              fill: red;
            }
            .cat-name {
              min-width: 130px;
              white-space: pre-line;
            }
          }
          .gr-item img {
            width: 30px;
            margin-right: 5px;
            padding: 5px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.react-multiple-carousel__arrow {
  background: #343C4B;
  outline: none !important;
  margin-top: -280px;
  min-width: unset;
  min-height: unset;
  width: 43px;
  height: 43px;
}

.react-multiple-carousel__arrow--right {
  right: 2px;
}
.react-multiple-carousel__arrow--left {
  left: 2px;
}

.view-top-cards {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  @media (max-width:767px) {
    flex-direction: column;
    align-items: end;
    .card-filter .feature-filter {
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .over-popup .ant-tooltip-content .ant-tooltip-inner .graph-info .gr-item {
    width: 97%;
  }
  .react-multiple-carousel__arrow {
    width: 35px;
    height: 35px;
    margin-top: -200px;
  }
  .react-multiple-carousel__arrow--right {
    right: 0px;
  }
  .react-multiple-carousel__arrow--left {
    left: 0px;
  }
  .card-item {
    padding: 0 10px 0 10px;
    .card-top-section {
      margin: 0 -10px;
    }
  }

}

.card-spinner {
  text-align: center;
  margin: 280px 0;
}


