/********************************************************
BUTTON
/********************************************************/
@import "_variable";

.primary-btn{
    //background-image: linear-gradient(to right, #FF8C5A , #FF9458, #FFA655);
    background-image: linear-gradient(to right, #FF8C5A, #FF9458, #ff9330);
    color: $white-color;
    &:hover{
        color: $white-color;
        opacity: 0.8;
    }
}

.unlink-red {
    background-image: linear-gradient(to right, #F76561 , #F76561, #F76561);
    color: $white-color;
    &:hover{
        color: $white-color;
        opacity: 0.8;
    }
}

.luci-btn{
    border: none;
    border-radius: 100px;
    font-size: $font-size-default;
    padding: 18px 30px;
    min-width: 175px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    height: 62px;
    &:focus{
        box-shadow: none;
        outline: none;
    }
    &:hover{
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
    }
    &:active{
        box-shadow: none;
        outline: none;
    }
}

.submit-btn{
    height: 62px;
    border-radius: 100px;
    font-size: 16px;
}

.gray-btn {
    background-color: rgba(147,147,147,0.41);
    color: #939393;
    &:hover {
        color: #939393;
        opacity: 0.8;
    }
}

.google-btn{
    img{
        margin-right: 5px;
    }
    .luci-btn{
        border: 2px solid #E9F0F4;
        background: #ffffff;
        height: 56px;
        color: #333333;
        font-weight: 600;
        font-size: 14px;
    }
}

/********************************************************
CHECK BOX
/********************************************************/

.custom-control{
    &:hover{
        cursor: pointer;
    }
}

.custom-control-input {
    &:checked ~ .custom-control-label::before {
        border-color: #FF8976;
        background-color: #FF8976;
    }

    &:focus ~ .custom-control-label::before {
        box-shadow: none;
    }
}


/********************************************************
TEXT COLOR
/********************************************************/

.p-text{
    color: $primary-text !important;
}

.s-text{
    color: $secondary-text !important;
}

.link-text{
    color: $link-text !important;
    &:hover{
        opacity: 0.8;
    }
}


/********************************************************
INPUT BOX
/********************************************************/

.form-group{
    margin-bottom: 20px;
    .luci-input{
        width: 100%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 100px;
        padding: 10px 5px 10px 20px;
        min-width: 383px;
        color: $input-text;
    }
    &.password-input{
        position: relative;
        .pwd-eye{
            position: absolute;
            right: 14px;
            transform: translateY(50%);
            margin-top: 7px;
            &:hover{
                cursor: pointer;
                opacity: 0.9;
            }
        }
    }
}

/********************************************************
SELECT
/********************************************************/


.custom-dropdown {
    position: relative;
    width: 100%;
    padding: 10px 40px 10px 20px;
    background: #fff;
    border-radius: 30px;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: none;
    cursor: pointer;
    outline: none;
    .dropdown {
        position: absolute;
        top: 40px;
        left: -1px;
        right: 0;
        padding-left: 0;
        width: 100.5%;
        background: white;
        border-radius: 4px;
        border: none;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        font-weight: normal;
        list-style: none;
        opacity: 0;
        pointer-events: none;
        li {
            a {
                display: block;
                padding: 10px;
                text-decoration: none;
                color: #222222;
                border-bottom: none;
                box-shadow: inset 0 1px 0 rgba(255,255,255,1);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-left: 4px solid #ffffff;
            }
            i {
                float: right;
                color: inherit;
            }
            &:hover a {
                background: #f6f6f6;
                border-left: 4px solid #FF8C5A;
            }
        }
    }
    &.active .dropdown {
        opacity: 1;
        pointer-events: auto;
        z-index: 9;
    }
    .dropdown-arrow-img {
        position: absolute;
        right: 10px;
        top: 15px;
        transition: all 0.5s ease;
        transform: rotate(180deg)
    }
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}
.no-opacity .custom-dropdown .dropdown, .no-pointerevents .custom-dropdown .dropdown {
    display: none;
    opacity: 1;
    pointer-events: auto;
}
.no-opacity .custom-dropdown.active .dropdown, .no-pointerevents .custom-dropdown.active .dropdown {
    display: block;
}

.page-not-found {
    .banner-left {
        align-self: center;
    }
    .banner-text {
        padding: 0;
    }
    .banner {
        padding: 50px 0 150px 0;
    }

    @media (max-width: 991px) {
        .banner-left {
            order: 1;
        }
    }
}

.your-account {
    .banner {
        .banner-text {
            text-align: left !important;
            padding-left: 0 !important;
        }
    }
    .account-setting {
        margin-top: 80px;
        width: 70%;
        .profile-info {
            margin-top: 30px;

            .profile-info-label {
                font-weight: bold;
                color: #aea9a9;
                border-bottom: 1px solid #dfdfdf;
                padding-bottom: 5px;
            }

            .name-input {
                margin-top: 15px;
                .save-btn {
                    margin-top: 15px;
                    text-align: right;
                }
            }

            .deactivate {
              color: #F76561;
              text-align: right;

              img {
                width: 18px;
              }
            }

            @media (min-width: 991px) {
                .name-input {
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;
                    .save-btn {
                        align-self: flex-end;
                    }
                }
            }
        }

        .ant-btn {
            min-width: 85px;
            border-radius: 5px;
            border-color: $secondary-text;
            color: $secondary-text;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.ant-input:hover {
    border-color: #FF8976;
}
.ant-input:focus {
    border-color: #FF8976;
    box-shadow: 0 0 0 2px rgba(255, 206, 121, 0.2)
}