.loader {
  background-image: url("../../../assets/images/loader.gif");
  background-repeat: no-repeat;
  background-position: center;
  &.big {
    height: 100vh;
    width: 100vw;
  }
  &.small {
    width: 100%;
    height: 100px;
    background-position: left;
    background-size: 100px;
  }
  &.center {
    background-position: center;
  }
}