.spending-behavior {
  margin-top: 30px;

  .chart-box {
    padding: 0 20px;
    text-align: center;
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 0;
      padding: 0;
    }
  }

  .spending-behavior-box {
    border: 1px solid #DDE1ED;
    box-sizing: border-box;
    border-radius: 10px;

    .highcharts-container {
      max-width: 100%;
    }


    .chart-content {
      min-height: 350px;
      margin-bottom: -60px;

      svg {
        max-width: 90%;
      }
    }

    .spending-behavior-table {
      margin: 40px 0;
      text-align: -webkit-center;
      .table-content {
        width: 90%;

        .table-row {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .td-1 {
            text-align: left;
            width: 35%;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .td-2 {
            text-align: left;
            width: 55%;
            margin-bottom: 10px;
          }
          .td-3 {
            text-align: right;
            width: 30%;
            margin-bottom: 10px;
          }
        }

        .border-div {
          border-bottom: 1px solid #dfdfdf;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }


}

text.highcharts-credits {
  display: none;
}

.scroll-table {
  max-height: 205px;
  overflow-y: auto;
  margin-right: -10px;

  &::-webkit-scrollbar {
    background-color:#fff;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color:#fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A0A0A5;
    border-radius:16px;
    border:5px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4;
  }

  &::-webkit-scrollbar-button {display:none}
}

.spending-pie-chart {
}


@media (min-width: 768px) {
  .spending-pie-chart, .spending-behavior-box {
  }
}

.relevant-transactions-modal {
  .ant-modal-content {
    height: 350px;
    .ant-modal-body {
      padding: 10px;
    }
  }
}
