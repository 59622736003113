/********************************************************
Banner SCSS  
/********************************************************/

.bg-header{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        background-image: url(../../assets/images/bg-a.svg);
        background-repeat: no-repeat;
        height: 772px;
        width: 100%;
        top: 0;
        left: auto;
        background-position: right top;
        right: 0;
        background-size: 60%;
        z-index: 1;
    }
    &:after{
        content: "";
        position: absolute;
        background-image: url(../../assets/images/bg-b.svg);
        background-repeat: no-repeat;
        height: 772px;
        width: 100%;
        top: 90px;
        left: auto;
        background-position: right top;
        right: 0;
        background-size: 45%;
        z-index: 0;
    }
    > .bg-c {
        &:before{
            content: "";
            position: absolute;
            background-image: url(../../assets/images/bg-c.svg);
            background-repeat: no-repeat;
            height: 772px;
            width: 100%;
            top: -30px;
            left: auto;
            background-position: right top;
            right: 0;
            background-size: 28.5%;
            z-index: 1;
        }
    }
}

.bg-header-b{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        background-image: url(../../assets/images/bg-a.svg);
        background-repeat: no-repeat;
        height: 772px;
        width: 100%;
        top: 0;
        left: auto;
        background-position: right;
        right: 0;
        background-size: 60%;
        z-index: 1;
    }
    &:after{
        content: "";
        position: absolute;
        background-image: url(../../assets/images/bg-b.svg);
        background-repeat: no-repeat;
        height: 772px;
        width: 100%;
        top: 90px;
        left: auto;
        background-position: right;
        right: 0;
        background-size: 45%;
        z-index: 0;
    }
    > .bg-c {
        &:before{
            content: "";
            position: absolute;
            background-image: url(../../assets/images/bg-c.svg);
            background-repeat: no-repeat;
            height: 772px;
            width: 100%;
            top: -30px;
            left: auto;
            background-position: right;
            right: 0;
            background-size: 28.5%;
            z-index: 1;
        }
    }
}

.banner{
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 50px 0 10px 0;
    .banner-text{
        padding: 80px 0 0 0;
        h1{
            font-size: 45px;
            font-weight: 700;
        }
        p{
            font-size: $font-size-medium;
        }
    }
    .card-items{
        margin-top: -135px;
        margin-right: 63px;
    }
    .card-lg{
        margin-top: -55px;
    }
    .card-area {
        margin: 30px 0;
    }
}


/********************************************************/
/* Max-Width 1200PX     *********************************/
/********************************************************/
@media (max-width: 1200px) {
    .bg-header {
        &:before{
            content: "";
            background-image: url(../../assets/images/bg-a.svg);
            background-position: right top;
            background-size: 75%;
            right: 0;
        }
        &:after{
            display: none;
        }
        > .bg-c {
            &:before{
                background-size: 30%;
                right: 0;
                top: 0;
            }
        }
    }


    .bg-header-b{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            background-image: url(../../assets/images/bg-a.svg);
            background-repeat: no-repeat;
            height: 772px;
            width: 100%;
            top: 0;
            left: auto;
            background-position: right top;
            right: 0;
            background-size: 60%;
            z-index: 1;
        }
        &:after{
            content: "";
            position: absolute;
            background-image: url(../../assets/images/bg-b.svg);
            background-repeat: no-repeat;
            height: 772px;
            width: 100%;
            top: 90px;
            left: auto;
            background-position: right top;
            right: 0;
            background-size: 45%;
            z-index: 0;
        }
        > .bg-c {
            &:before{
                content: "";
                position: absolute;
                background-image: url(../../assets/images/bg-c.svg);
                background-repeat: no-repeat;
                height: 772px;
                width: 100%;
                top: -30px;
                left: auto;
                background-position: right top;
                right: 0;
                background-size: 28.5%;
                z-index: 1;
            }
        }
    }
}




/********************************************************/
/* Max-Width 991PX     **********************************/
/********************************************************/
@media (max-width: 991px) {
    .bg-header{
        &:before{
            background-size: 75%;
            right: 0;
        }
        &:after{
            background-size: 50%;
            right: 0;
            top: 0;
        }
        > .bg-c {
            &:before{
                background-position: right top;
            }
        }
    }
    .banner-right{
        order: 1;
    }
    .banner-left{
        order: 2;
    }
    .banner {
        .card-area{
            text-align: center !important;
        }
        .card-items {
            margin-top: -125px;
            margin-right: 220px;
        }
        .banner-text {
            text-align: center !important;
            padding: 40px 0 0 0;
            h1{
                font-size: 34px;
                line-height: 54px;
            }
        }
        .btn-area {
            text-align: center;
        }
    }
}


/********************************************************/
/* Max-Width 576PX     **********************************/
/********************************************************/
@media (max-width: 576px) {
    .bg-header{
        &:before{
            content: "";
            background-image: url(../../assets/images/mob-view.svg);
            background-size: 100%;
            right: 0;
        }
        &:after{
            display: none
        }
        > .bg-c {
            &:before{
                display: none
            }
        }
    }
    .banner{
        padding: 10px 0 50px 0;
        .card-lg{
            margin-top: 0;
            margin-left: 0;
            text-align: right;
            img{
                width: 65%;
            }
        }
        .card-items {
            margin-top: -100px;
            margin-right: 0;
            img{
                width: 80%;
            }
        }
        .banner-text {
            padding: 0 20px 0 20px;
        }
    }
    .bg-header-b:before{
        background-size: 70%;
    }
    .about-pt-50 {
        padding-top: 50px !important;
    }
}