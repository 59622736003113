/********************************************************
Basic Color Variables
/********************************************************/

$white-color:     #ffffff;
$black-color:     #000000;

/********************************************************
Theme Color Variables
/********************************************************/

$primary-text:    #222222;
$secondary-text:  #FF8976;
$input-bg:        #FAFAFB;
$input-border:    #F1F1F5;
$input-text:      #92929D;
$link-text:       #FDA586;

/********************************************************
Font family & Size Variables
/********************************************************/

$font-size-medium:    	20px;
$font-size-primary:   	18px;
$font-size-regular:   	16px;
$font-size-default:   	14px; 
$font-size-smallar:   	12px;
$font-size-smallest:  	10px;
$font-family-default: 	'Poppins', sans-serif;
