@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.fs-11 {
  font-size: $fs_11 !important;
}
.fs-12 {
  font-size: $fs_12 !important;
}
.fs-13 {
  font-size: $fs_13;
}
.fs-14 {
  font-size: $fs_14 !important;
}
.fs-15 {
  font-size: $fs_15 !important;
}
.fs-16 {
  font-size: $fs_16 !important;
}
.fs-17 {
  font-size: $fs_17 !important;
}
.fs-18 {
  font-size: $fs_18 !important;
}
.fs-21 {
  font-size: $fs_21;
}
.fs-22 {
  font-size: $fs_22 !important;
}
.fs-24 {
  font-size: $fs_24;
}
.fs-25 {
  font-size: $fs_25 !important;
}
.fs-28 {
  font-size: $fs_28;
}
.fs-32 {
  font-size: $fs_32;
}
.fs-34 {
  font-size: $fs_34;
}
.fs-42 {
  font-size: $fs_42;
}
.fw-500 {
  font-weight: $fw_500;
}
.fw-600 {
  font-weight: $fw_600;
}
.fw-900 {
  font-weight: $fw_900;
}
.fw-bold {
  font-weight: $fw_bold;
}
.fw-normal {
  font-weight: $fw_normal;
}
.m-10 {
  margin: $m_10;
}
.mt-5 {
  margin-top: $m_5;
}
.mr-5px {
  margin-right: $m_5;
}
.mb-5 {
  margin-bottom: $m_5;
}
.ml-8 {
  margin-left: $m_8;
}
.mt-10 {
  margin-top: $m_10;
}
.mb-10 {
  margin-bottom: $m_10;
}
.ml-10 {
  margin-left: $m_10;
}
.mr-10 {
  margin-right: $m_10;
}
.mt-12 {
  margin-top: $m_12;
}
.mb-12 {
  margin-bottom: $m_12;
}
.ml-12 {
  margin-left: $m_12;
}
.mr-12 {
  margin-right: $m_12;
}
.mt-15 {
  margin-top: $m_15;
}
.mb-15 {
  margin-bottom: $m_15;
}
.ml-15 {
  margin-left: $m_15;
}
.mt-17 {
  margin-top: $m_17;
}
.mb-17 {
  margin-bottom: $m_17;
}
.ml-16 {
  margin-left: $m_16;
}
.mr-16 {
  margin-right: $m_16;
}
.mr-15 {
  margin-right: $m_15;
}
.mt-20 {
  margin-top: $m_20;
}
.mb-20 {
  margin-bottom: $m_20;
}
.mt-23 {
  margin-top: $m_23;
}
.mt-25 {
  margin-top: $m_25;
}
.mb-25 {
  margin-bottom: $m_25;
}
.mt-30 {
  margin-top: $m_30;
}
.mb-30 {
  margin-bottom: $m_30;
}
.mr-34 {
  margin-right: $m_34;
}
.ml-34 {
  margin-left: $m_34;
}
.mt-35 {
  margin-top: $m_35;
}
.mb-35 {
  margin-bottom: $m_35;
}

.mr-40 {
  margin-right: $m_40;
}
.ml-40 {
  margin-left: $m_40;
}
.mt-40 {
  margin-top: $m_40;
}
.mb-40 {
  margin-bottom: $m_40;
}
.mt-45 {
  margin-top: $m_45;
}
.mb-45 {
  margin-bottom: $m_45;
}
.mt-50 {
  margin-top: $m_50;
}
.mb-50 {
  margin-bottom: $m_50;
}
.mt-55 {
  margin-top: $m_55;
}
.mb-55 {
  margin-bottom: $m_55;
}

.mt-60 {
  margin-top: $m_60;
}
.mb-60 {
  margin-bottom: $m_60;
}
.mt-70 {
  margin-top: $m_70;
}
.mb-70 {
  margin-bottom: $m_70;
}
.mt-90 {
  margin-top: $m_90;
}
.mb-90 {
  margin-bottom: $m_90;
}
.mt-95 {
  margin-top: $m_95;
}
.mb-95 {
  margin-bottom: $m_95;
}
.mt-100 {
  margin-top: $m_100;
}
.mt-120 {
  margin-top: $m_120;
}
.mb-100 {
  margin-bottom: $m_100;
}
.mt-300 {
  margin-top: $m_300;
}

.p-10 {
  padding: $m_10;
}
.p-18 {
  padding: $m_18;
}
.pt-5 {
  padding-top: $m_5;
}
.pb-5 {
  padding-bottom: $m_5;
}
.pt-10 {
  padding-top: $m_10;
}
.pb-10 {
  padding-bottom: $m_10;
}
.pl-10 {
  padding-left: $m_10;
}
.pr-10 {
  padding-right: $m_10;
}
.pt-12 {
  padding-top: $m_12;
}
.pb-12 {
  padding-bottom: $m_12;
}
.pl-12 {
  padding-left: $m_12;
}
.pr-12 {
  padding-right: $m_12;
}
.pt-15 {
  padding-top: $m_15;
}
.pr-15 {
  padding-right: $m_15;
}
.pl-15 {
  padding-left: $m_15;
}
.pb-15 {
  padding-bottom: $m_15;
}
.pt-20 {
  padding-top: $m_20;
}
.pb-20 {
  padding-bottom: $m_20;
}
.pl-18 {
  padding-left: $m_18;
}
.pr-18 {
  padding-right: $m_18;
}
.pt-25 {
  padding-top: $m_25;
}
.pb-25 {
  padding-bottom: $m_25;
}
.pt-30 {
  padding-top: $m_30;
}
.pb-30 {
  padding-bottom: $m_30;
}
.pt-35 {
  padding-top: $m_35;
}
.pb-35 {
  padding-bottom: $m_35;
}
.pt-40 {
  padding-top: $m_40;
}
.pb-40 {
  padding-bottom: $m_40;
}
.pl-40 {
  padding-left: $m_40;
}
.pt-50 {
  padding-top: $m_50;
}
.pb-50 {
  padding-bottom: $m_50;
}
.pt-55 {
  padding-top: $m_55;
}
.pb-55 {
  padding-bottom: $m_55;
}

.w-10 {
  width: 10px !important;
}

.w-20 {
  width: 20px !important;
}

.w-25 {
  width: 25px !important;
}

.w-30 {
  width: 30px !important;
}

.w-80 {
  width: 80px !important;
}

.w-120 {
  width: 120px !important;
}

.w-200 {
  width: 200px !important;
}

.width-10p {
  width: 10%;
}

.width-20p {
  width: 20%;
}

.lh-25 {
  line-height: 25px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.color-white {
  color: #ffffff !important;
}

.color-gray {
  color: gray !important;
}

.color-link {
  color: #43aade !important;
}

.flex-unset {
  flex: unset !important;
}

.br-50 {
  border-radius: 50%;
}

.ant-message .anticon {
  top: unset;
  bottom: 2.5px;
}


a.nav-link {
  -webkit-appearance: none !important;
}