/********************************************************
Header SCSS
/********************************************************/
@import "_variable";

header{
  .navbar-light {
    z-index: 2;
    padding-right: 0;
    .navbar-nav .nav-link{
      color: $white-color;
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      &:hover{
        color: $white-color;
        text-decoration: none;
        opacity: 0.9;
      }
      &:focus, &:active{
        color: $white-color;
      }
    }
    .navbar-collapse{
      .navbar-nav .nav-link{
        &:hover{
          color: $white-color;
        }
        &:focus, &:active{
          color: $white-color;
        }
      }
    }
  }
}

.user-name {
  a {
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
  }
  span {
    display: block;
    font-weight: 600;
    color: $primary-text;
  }
  .short-name {
    font-weight: 400;
    display: block;
    background: linear-gradient(90deg,#FF8C5A 7.91%,#FF9458 51.05%,#FFA655 94.63%);
    box-shadow: 0px 10px 20px rgba(253,165,134,0.1);
    border-radius: 100px;
    width: 44px;
    height: 44px;
    text-align: center;
    padding-top: 12px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
  }
  &.home-login{
    position: relative;
    span{
      color:$white-color;
    }
    .dark-txt{
      color:#222222;
    }
  }
}

.logout-area{
  visibility: visible;
  opacity: 1;
  top: 47px;
  left: auto;
  min-width: 150px;
  background: $white-color;
  right: 0;
  line-height: 50px;
  border-radius: 5px;
  transition: all 0.7s ease;
  position: absolute;
  border: 1px solid #dfdfdf;
  .account {
    border-bottom: 1px solid #dfdfdf;
  }
  a{
    color: #222222;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    &:hover{
      opacity: 0.7;
    }
  }
}

.bg-header-b{
  margin-bottom: 30px;
}

/********************************************************/
/* Max-Width 991PX     **********************************/
/********************************************************/
@media (max-width: 991px) {
  header{
    .navbar-collapse{
      position: absolute;
      width: 100%;
      right: 0;
      top: 58px;
      left: 0;
      .navbar-nav .nav-link{
        &:hover{
          color: $primary-text;
        }
        &:focus, &:active{
          color: $primary-text;
        }
      }
    }
    .navbar-light{
      padding: 10px 0 0 10px !important;
      z-index: 2;
    }
    .navbar-toggler{
      padding: 0;
      border: none;
      margin-top: 0;
      position: absolute;
      right: 10px;
    }
    .btn-signup{
      position: absolute;
      left: auto;
      right: 70px;
      top: 2px;
      .luci-btn{
        padding: 10px 20px;
        min-width: 130px;
      }
    }
    .navbar-nav{
      background: $white-color;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 48px 0 rgba(0,0,0,0.08);
      box-shadow: 0 2px 48px 0 rgba(0,0,0,0.08);
      .nav-link{
        color: $primary-text !important;
      }
    }
  }
  .btn-signup .luci-btn{
    height: 46px;
  }
  .user-name.home-login{
    right: 70px;
  }
}
