.chart-inner-text {
  position: absolute;
  text-align: center;
  font-size: 13px;
  width: 250px;
  align-content: center;
  h3.ci-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
    &.reward-color {
      color: #FF8C5A;
    }
  }
  div{
    margin-bottom: 0;
    hr{
      width: 70px;
    }
  }
  span.ci-value {
    font-size: 14px;
    color: #696974;
    &.reward-color {
      color: #FF8C5A;
    }
  }
}
.chart-area {
  margin-top: 50px;
  width: 300px;
}

.line-bar-chart {
  .chart-inner-text {
    position: unset;
    justify-content: center;
    width: unset;
    .reward-txs {
      border-left: 1px solid #dfdfdf;;
    }
  }
  .line-chart-area {
    width: 100%;
    display: flex;
    height: 35px;
    padding: 0 15px;
    .chart-line {
      padding: 15px 0;
    }
  }
}

.avg-spending-month {
  font-size: 15px;
  margin: 15px 0 15px 10px;
}

.date-month{
  font-size: 15px;
  margin: 15px 0 15px 10px;
}

.chart-inner-value {
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  font-size: 15px;
  padding-bottom: 5px;
}

.table-card {
  .ant-btn{
    border-radius: 25px;
  }
  .ant-btn:hover, .ant-btn:focus {
    color: #ff9459;
    background: #fff;
    border-color: #ff9459;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #ff9459;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #ff9459;
  border-color: #ff9459;
}

.ant-checkbox-checked::after{
  border: 1px solid #ff9459;
}

.sorting-arrow{
  position: absolute;
  right: 10px;
  top: 40%;
  width: 16px;
  .sorting-asc{
    content: "";
    background-image: url("../../../../../../assets/images/dashboard/table/up-arrow.png");
    height: 10px;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    &:hover{
      cursor: pointer;
    }
    &.gray{
      opacity: 0.6;
    }
  }
  .sorting-desc{
    content: "";
    background-image: url("../../../../../../assets/images/dashboard/table/up-arrow.png");
    height: 10px;
    width: 100%;
    position: absolute;
    right: 0;
    top: 11px;
    transform: rotate(180deg);
    &:hover{
      cursor: pointer;
    }
    &.gray{
      opacity: 0.5;
    }
  }
}

.slider-card-title{
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.small-slider-card-title{
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  min-height: 60px;
  font-weight: 400;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .slider-card-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 22px;
    text-align: center;
    padding: 0px 10px;
    margin-top: 10px;
  }
}

/********************************************************/
/* Max-Width 991PX     *********************************/
/********************************************************/
@media (max-width: 991px) {
  .chart-area{
    margin: 0 auto;
  }
}