#dateSelector {
  .ant-picker-range.ant-picker {
    position: absolute;
    left: 100%;
    top: 5px;
    background: #ffffff;
    width: 250px;
    height: 46px;
    z-index: 9;
    +div {
      top: -15px;
    }
    .ant-picker-focused{
      border-color: #ff8e59;
    }

    @media (max-width: 600px) {
      left: 0;
      top: unset;
      bottom: -45px;
    }
  }
  .ant-picker-focused{
    box-shadow: none;
  }
  .ant-picker-separator{
    margin-top: -4px;
  }
  .ant-picker:hover, .ant-picker-focused{
    border-color: #ff8e59;
  }
  .ant-picker-input > input:hover{
    border-color: #ff8e59;
  }
  .ant-picker-range .ant-picker-active-bar{
    background: #ff8e5a;
    height: 4px;
  }
  .ant-picker-header{
    border-bottom: 1px solid #ff8d5b;
    background: #ff8d5b;
    button.ant-picker-month-btn,button.ant-picker-year-btn {
      color: #fff;
    }
  }
  .ant-picker-range-arrow::after {
    border: 5px solid #ff8d5b;
    border-color: #ff8d5b #ff8d5b transparent transparent;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background: #ff8d5b;
    border-radius: 15px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #f5e5de;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #f5e5de;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #ff8d5b;
  }

  @media (max-width: 767px) {
      .ant-picker-panels {
        display: flex;
        flex-flow: column;
      }
  }
}

.ant-picker-header-view button:hover,button.ant-picker-decade-btn {
  color: #ffffff;
}

// why is global ant-popover styling done here? not modular
/* 
.ant-popover .ant-popover-inner-content {
  background: #fff;
  width: 185px;
  padding: 0;
  border-radius: 0;
  text-align: left;
  font-size: 14px;
  z-index: 9;
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
  li{
    list-style-type: none;
    padding: 0;
    a{
      padding: 8px 5px 8px 15px;
      cursor: pointer;
      display: block;
      color: #222222;
      text-decoration: none;
      background: #ffffff;
      &:hover{
        background: #F6F6F6;
      }
    }
  }
  ul{
    padding: 0;
    margin: 0;
  }
}
*/

.run-test {
  text-align: center;
  padding: 5px !important;
  button {
    color: white;
    background: #ff8d5b;
    border-color: #ff8d5b;
  }
  button:hover {
    color: white;
    background: #ff8d5b;
    border-color: #ff8d5b;
  }
}

.card-range {
  display: flex;
  .value-class {
    border: 1px solid #b5adad;
    padding: 2px 5px;
    border-radius: 5px;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
  }
  .calender-box {
    cursor: pointer;
    &:hover {
      box-shadow: 3px 2px 10px 2px #ccc;
    }
  }
  .help-icon {
    border: 1px solid #b5adad;
    width: 30px;
    padding: 2px 4.5px;
    border-radius: 5px;
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
    border-left: none;
  }
}

.chart-title {
  padding: 0 60px;
  padding-top: 10px;
}

